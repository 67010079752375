const Home = () => {
    return (
        <div className="App">
      <header>
   {/* <nav>
        <ul>
            <li><a href="#how-it-works">How it Works</a></li>
            <li><a href="#pricing">Pricing</a></li>
            <li><a href="#faq">FAQ</a></li>
        </ul>
        <button>Book a Call</button>
    </nav>
  */}
</header>
<main>
    <section className="hero">
        <h1>Transform UI Designs into<br></br>Mobile Apps with Lightning Speed!</h1>
        <p>No need to post jobs, no recruitment required, no stress involved. <br></br> Simply get high-functioning mobile applications.</p>
        <aside>
        <div className="my-avatar">
            <img className="my-avatar-img" src="../images/my-avatar.png"></img>
        </div>
    </aside>
        <div className="button__group"><a href="/form" className="button hero w-inline-block"><div>Get Started</div></a></div>
    </section>
    
</main>
<footer>
   {/*<p>Some recent work</p> */} 
</footer>
    </div>
    );
  };
  
  export default Home;
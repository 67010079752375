
const RequestForm = () => {

    return(
        <div>
           <section className="hero" style={{height: '80vh'}}>
            <iframe src="https://tally.so/r/3X00NO?transparentBackground=1" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="Mobile App Development"></iframe>
            </section>
        </div>
    )
}

export default RequestForm;